import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { APIApplication } from 'types/Application'
import AdvancedTable from 'components/AdvancedTable'
import Button from '@mui/material/Button'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import ActionDropdown from 'components/ActionDropdown'
import { useNavigate } from 'react-router'
import Typography from '@mui/material/Typography'
import { StatusTypes, applicationStatuses } from 'pages/Applications/applicationStatuses'
import { useAppContext } from 'context/AppContext/AppContext'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosPatch } from 'connectors/axiosPatch'
import { renderDate } from 'utils/renderDate'
import { AbilityContext, Can } from 'config/ability'
import type { UpdateStatusData } from './Applications.types'
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined'
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined'
import Link from '@mui/material/Link'
import IconChip from 'components/IconChip'
import { useAlert } from 'context/AlertContext'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import { generateQueryParams } from 'utils/generateQueryParams'
import { renderCells } from 'utils/renderCells'
import { Backdrop, CircularProgress } from '@mui/material'
import { axiosDelete } from 'connectors/axiosDelete'
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined'
import { type AxiosResponse } from 'axios'
import { useAbility } from '@casl/react'

const Applications = (): JSX.Element => {
  const queryClient = useQueryClient()
  const [isExportMode, setIsExportMode] = useState<boolean>(false)
  const [isExporting, setIsExporting] = useState<boolean>(false)
  const [elementToDelete, setElementToDelete] = useState<APIApplication | null>(null)
  const [elementToHardDelete, setElementToHardDelete] = useState<APIApplication | null>(null)
  const { changeMessage } = useAlert()
  const { handleRefetch } = useAppContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { markets, barcodes } = useAppContext()
  const { token, refreshToken, get2fa } = useAuthContext()
  const [filtersParams, setFiltersParams] = useState<string>('')
  const ability = useAbility(AbilityContext)

  const { mutate: updateStatusMutate, data: updateStatusResponse, reset } = useMutation(
    async ({
      id,
      newStatusId
    }: UpdateStatusData) => await axiosPatch<{ affected: number }>(token, refreshToken, `applications/status/${id}`,
      { status: newStatusId }
    ),
    {
      onSuccess: (response) => {
        if (response !== undefined && !('error' in response)) {
          void queryClient.refetchQueries(['applications-query'])
        }
      }
    }
  )

  const { mutate: hardDelete, data: hardDeleteResponse, isLoading } = useMutation(
    async (id: number) => await axiosDelete(token, refreshToken, `applications/${id}`),
    {
      onSuccess: (response) => {
        if (response !== undefined && !('error' in response)) {
          void queryClient.refetchQueries(['applications-query'])
        }
      }
    }
  )

  useEffect(() => {
    void queryClient.refetchQueries({ exact: false, queryKey: ['applications-query'] })
    if (typeof updateStatusResponse !== 'string' && updateStatusResponse !== undefined && 'error' in updateStatusResponse) {
      changeMessage(updateStatusResponse?.error?.data?.message, 'error', reset)
    }
    if (typeof updateStatusResponse !== 'string' && updateStatusResponse !== undefined && !('error' in updateStatusResponse) && updateStatusResponse.affected > 0) {
      changeMessage(t('common.success'), 'success', () => {
        reset()
      })
    }
  }, [updateStatusResponse])

  useEffect(() => {
    if (!isLoading) {
      setElementToHardDelete(null)
      void queryClient.refetchQueries({ exact: false, queryKey: ['applications-query'] })
    }
  }, [hardDeleteResponse, isLoading])

  const handleDownloadApplications = async (filterToDownload: Record<string, string | string[]>): Promise<any> => {
    setIsExporting(true)
    const params = generateQueryParams(filterToDownload)

    const url = `${process.env.REACT_APP_API_URL}/applications/export?${params.toString()}`

    const options = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    const res = await fetch(url, options)
    if (res.ok) {
      const blob = await res.blob()
      const file = window.URL.createObjectURL(blob)
      window.location.assign(file)
      setIsExportMode(false)
      setIsExporting(false)
      void queryClient.refetchQueries(['applications-query'])
    } else {
      const data = await res.json()
      setIsExportMode(false)
      setIsExporting(false)
      if (data?.message !== undefined) {
        changeMessage(data.message, 'error', () => {
        })
      }
    }
  }

  const { mutate: anonymize, reset: resetAnonymize } = useMutation(
    async ({
      id,
      tfa
    }: { id: string, tfa: string }) => await axiosPatch<AxiosResponse<string>>(token, refreshToken, `applications/anonymize/${id}`, { tfa }),
    {
      onSuccess: () => {
        void queryClient.refetchQueries(['markets-query'])
        handleRefetch()
      },
      onSettled: (data) => {
        if (data !== undefined && typeof data === 'string' && data === '') {
          changeMessage(t('common.success'), 'success', resetAnonymize)
        }
        if (data !== undefined && 'error' in data && data.error !== undefined) {
          changeMessage(data?.error?.data?.message, 'error', resetAnonymize)
        }
      }
    })

  const handleHideDialog = (): void => {
    setElementToDelete(null)
  }

  const handleDelete = (): void => {
    if (elementToDelete !== null) {
      updateStatusMutate({ id: elementToDelete.application_id, newStatusId: StatusTypes.Deleted })
      setElementToDelete(null)
    }
  }

  const changeExportMode = (value: boolean): void => {
    setIsExportMode(value)
  }

  return (
        <>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='h4' component='h1' mb={2}>{t('navigation.applications')}</Typography>
                <Can I='download' a='Applications'>
                    <Button
                        onClick={() => {
                          setIsExportMode(true)
                        }}
                        type='button'
                        variant='contained'
                        color='info'
                        sx={{ ml: 'auto', mr: 1 }}
                        startIcon={<VerticalAlignBottomOutlinedIcon/>}
                    >
                        {t('common.export')}
                    </Button>
                </Can>
                <Can I='create' a='Applications'>
                    <Button variant='contained' onClick={(): void => {
                      navigate('/applications/add')
                    }} startIcon={<PlaylistAddOutlinedIcon/>}>{t('common.add')}</Button>
                </Can>
            </Box>
            <AdvancedTable<APIApplication>
                name='applications'
                endpoint='applications'
                orderField='createdAt'
                orderDir='DESC'
                isExportMode={isExportMode}
                exportText={t('common.download')}
                changeExportMode={changeExportMode}
                onUsedFilters={async (filters) => {
                  if (isExportMode) {
                    await get2fa(async (tfa) => {
                      await handleDownloadApplications({ ...filters, tfa })
                    })
                  }
                  const status = typeof filters.status === 'boolean' ? [filters.status.toString()] : (filters.status ?? [])
                  const marketId = typeof filters.marketId === 'boolean' ? [filters.marketId.toString()] : (filters.marketId ?? [])
                  setFiltersParams(generateQueryParams({ status, marketId }).toString())
                }}
                customFilters={[
                  { type: 'text', name: 'Address', field: 'address', sort: false }
                ]}
                headings={[
                  { type: 'text', name: 'ID', field: 'id', sort: true, width: 90, order: 1 },
                  { type: 'text', name: t('common.email'), field: 'email', sort: true, width: 220, order: 4 },
                  {
                    type: 'datetime-range',
                    name: t('common.createdAt'),
                    field: 'createdAt',
                    sort: true,
                    width: 140,
                    order: 3
                  },
                  {
                    type: 'datetime-range',
                    name: `${t('common.createdAt')} local`,
                    field: 'createdAtLocal',
                    sort: true,
                    width: 140,
                    order: 3
                  },
                  {
                    type: 'datetime-range',
                    name: `${t('common.verifiedAt')} local`,
                    field: 'verifiedAtLocal',
                    sort: true,
                    width: 140,
                    order: 3
                  },
                  { type: 'options', name: t('common.marketName'), field: 'marketId', width: 170, order: 2 },
                  { type: 'empty', name: t('application.language'), field: 'language', width: 170, order: 4 },
                  { type: 'text', name: t('common.barcode'), field: 'barcode', width: 120, order: 4 },
                  { type: 'multipicker', name: t('common.status'), field: 'status', sort: true, width: 120, order: 1 },
                  { type: 'empty', name: t('common.actions'), field: '', width: 90, order: 4 }
                ]}
                options={{
                  marketId: markets !== undefined && 'data' in markets
                    ? markets.data.map(el => ({
                      id: el.id.toString(),
                      value: el.name
                    })).sort((a, b) => a.value.localeCompare(b.value))
                    : [],
                  status: applicationStatuses.map((status, idx) => ({ id: idx.toString(), value: status.value }))
                }}
                renderRow={(row, idx) => (
                    <TableRow key={idx}>
                        {renderCells([
                          {
                            label: 'ID',
                            value: <Link
                                    href={`/applications/${row.application_id}?${filtersParams.toString()}`}>{row.application_id}</Link>
                          },
                          {
                            label: t('common.email'),
                            value: <Link
                                    href={`/applications/${row.application_id}?${filtersParams.toString()}`}>{row.application_email}</Link>
                          },
                          { label: t('common.createdAt'), value: renderDate(row.application_createdAt, true) },
                          {
                            label: `${t('common.createdAt')} local`,
                            value: renderDate(row.application_createdAtLocal, true)
                          },
                          {
                            label: `${t('common.verifiedAt')} local`,
                            value: row.application_verifiedAtLocal !== null ? renderDate(row.application_verifiedAtLocal, true) : ''
                          },
                          { label: t('common.marketName'), value: row.market_name ?? 'N/A' },
                          { label: t('application.language'), value: row.marketLanguage_name ?? 'N/A' },
                          {
                            label: t('common.barcode'),
                            value: <Typography
                                    color={barcodes?.data.some(e => e.code === row.application_barcode) ? 'warning.main' : ''}>{row.application_barcode}</Typography>
                          },
                          {
                            label: t('common.status'),
                            value: row.application_status in applicationStatuses
                              ? <IconChip
                                        icon={applicationStatuses[row.application_status].icon}
                                        color={applicationStatuses[row.application_status].color}
                                        label={applicationStatuses[row.application_status].value ?? 'N/A'}
                                    />
                              : null
                          }
                        ])}
                        <ActionDropdown
                            subject='Applications'
                            name={'application'}
                            buttons={[
                              {
                                label: t('common.edit'),
                                name: 'edit-application',
                                handler: (): void => {
                                  navigate(`/applications/edit/${row.application_id}`)
                                },
                                disabled: row.application_status === StatusTypes.Deleted,
                                icon: <EditOutlinedIcon color='info'/>
                              },
                              {
                                label: t('common.details'),
                                name: 'details-application',
                                handler: (): void => {
                                  navigate(`/applications/${row.application_id}`, { state: { ...row } })
                                },
                                icon: <VisibilityOutlinedIcon color='info'/>
                              },
                              {
                                label: t('common.anonymize'),
                                name: 'anonymize-market',
                                handler: (): void => {
                                  void get2fa(async (tfa) => {
                                    anonymize({ id: row.application_id.toString(), tfa })
                                  })
                                },
                                disabled: !ability.can('hardDelete', 'Applications'),
                                icon: <KeyOutlinedIcon color='info'/>
                              },
                              {
                                label: t('common.delete'),
                                name: 'delete-application',
                                handler: (): void => {
                                  setElementToDelete(row)
                                },
                                disabled: !ability.can('hardDelete', 'Applications') || row.application_status === StatusTypes.Deleted,
                                icon: <DeleteOutlineOutlinedIcon color='error'/>
                              },
                              {
                                label: `"hard" ${t('common.delete')}`,
                                name: 'hard-delete-application',
                                handler: (): void => {
                                  setElementToHardDelete(row)
                                },
                                disabled: !ability.can('hardDelete', 'Applications') || row.application_status !== StatusTypes.Deleted,
                                icon: <DeleteOutlineOutlinedIcon color='error'/>
                              }
                            ]}
                        />
                    </TableRow>
                )}
            />
            {elementToDelete !== null
              ? <ConfirmDialog
                    open={elementToDelete !== null}
                    onAgree={handleDelete}
                    onDisagree={handleHideDialog}
                    question={t('dialogs.delete')}
                />
              : null}
            {elementToHardDelete !== null
              ? <ConfirmDialog
                    open={elementToHardDelete !== null}
                    onAgree={() => {
                      hardDelete(elementToHardDelete.application_id)
                    }}
                    onDisagree={handleHideDialog}
                    question={t('dialogs.delete')}
                />
              : null}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
                open={isExporting}
            >
                <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
                    <CircularProgress color='warning'/>
                    <Typography fontFamily='inherit' fontWeight={800}>{t('application.exporting')}</Typography>
                </Box>
            </Backdrop>
        </>
  )
}

export default Applications
